import { useEffect, useState } from 'react';
import propertyService from '../../service/property.service';
import DynamicPropertyTable from './DynamicPropertyTable';

const DynamicPropertyMain = ({ submenuId, disabled, selectedValues, canEdit }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (submenuId > 0) {
      getFormProperties();
    }
  }, [submenuId]);

  const getFormProperties = () => {
    propertyService.getPropertiesByFormId(submenuId).then((data) => {
      if (!canEdit) {
        const teszt = data.filter(
          (filtered) => filtered.property.name !== 'Szerződött alap óradíj' && filtered.property.id !== 16
        );
        setRows(teszt);
      } else {
        setRows(data);
      }
    });
  };

  return <DynamicPropertyTable rows={rows} disabled={disabled} selectedValues={selectedValues} />;
};

export default DynamicPropertyMain;
