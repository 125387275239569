import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, Form, Formik } from 'formik';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { dataGridColumns } from '../../utils/helper';
import Yup from '../../utils/yup';
import attendenceService from '../../service/attendence.service';

import { DataGridUI, DataGridIdentifiers, ButtonUI, FormErrorUI, InputUI, AutoCompleteSelectUI } from '../Interface';

function EditToolbar(props) {
  const { setRows, setRowModesModel, rows, rowModesModel, setCurrentRow, id } = props;

  const handleClick = () => {
    const newRow = {
      listId: Date.now(),
      userId: '',
      timeSpent: null,
      isNew: true,
    };

    setRows((oldRows) => [newRow, ...oldRows]);
    setCurrentRow(newRow);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRow.listId]: { mode: GridRowModes.Edit, fieldToFocus: 'id' },
    }));
  };

  const isNewRow = rows.some(
    (row) => row.isNew || Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit)
  );

  return (
    <GridToolbarContainer>
      <ButtonUI className="bg-success mb-1" text="Új sor hozzáadása" disabled={isNewRow || !id} onClick={handleClick} />
    </GridToolbarContainer>
  );
}

const AttendanceUsers = ({ attendance, id, setRefetchTable, user }) => {
  const { attendanceUserList, setAttendanceUserList } = attendance;
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [currentRow, setCurrentRow] = useState({});

  useEffect(() => {
    setRows(attendanceUserList);
  }, [attendanceUserList]);

  const validationSchema = Yup.object().shape({
    userId: Yup.string().required(),
    timeSpent: Yup.number().required().positive().max(24),
  });

  const handleAddRow = (updatedRow) => {
    const insertObject = {
      ...updatedRow,
      createdBy: user?.userId,
    };
    attendenceService.addAttendenceUserToList(insertObject, id).then((data) => {
      addNotification({
        content: 'Sikeres hozzáadás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setAttendanceUserList((prev) => {
        return [...prev, data];
      });
    });
  };

  const handleUpdateRow = (updatedRow, listId) => {
    const updData = {
      ...updatedRow,
      updatedBy: user?.userId,
    };
    attendenceService.updateAttendenceUser(updData, listId).then(() => {
      addNotification({
        content: 'Sikeres mentés!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
    });
  };

  const handleDeleteRow = (listId) => {
    const deleteData = {
      deletedBy: user.userId,
    };
    attendenceService.deleteAttendenceUserFromList(deleteData, listId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setAttendanceUserList((prevOption) => prevOption.filter((option) => option.listId !== listId));
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const rowToEdit = rows.find((row) => row.listId === id);
    setCurrentRow(rowToEdit);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (listId) => () => {
    setRows(rows.filter((row) => row.listId !== listId));
    handleDeleteRow(listId);
  };

  const handleCancelClick = (id) => () => {
    const editedRow = rows.find((row) => row.listId === id);

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.listId !== id));
    } else {
      setRows((prevRows) => prevRows.map((row) => (row.listId === id ? { ...editedRow, isNew: false } : row)));
    }
    setCurrentRow(null);
  };

  const processRowUpdate = async (newRow) => {
    await validationSchema.validate(newRow, { abortEarly: false });
    const updatedRow = { ...newRow, isNew: newRow.isNew ?? false };
    const updatedRows = rows.map((row) => (row.listId === newRow.listId ? updatedRow : row));

    updatedRows.sort((a, b) => a.listId - b.listId);

    setRows(updatedRows);

    if (updatedRow.isNew) {
      handleAddRow(updatedRow);
    } else {
      handleUpdateRow(updatedRow, newRow.listId);
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors) => {
    return dataGridColumns([
      {
        field: 'listId',
        headerName: 'Azon.',
        flex: 0.5,
        valueGetter: (params) => (params?.row.isNew ? '' : params.row.listId),
      },
      {
        field: 'userId',
        headerName: 'Felhasználó',
        flex: 1,
        editable: true,
        renderCell: (params) => {
          return params.row.resourceOne?.name;
        },
        renderEditCell: (params) => (
          <Field
            name="userId"
            component={AutoCompleteSelectUI}
            helperText={<FormErrorUI message={errors.userId} />}
            selectedValue={params.row?.userId}
            selectedLabelValue={params.row.resourceOne?.name}
            onChange={(_event, newValue) => {
              setFieldValue('userId', newValue?.value ?? null).then(() => {
                validateField('userId');
              });
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: newValue ? newValue.value : null,
              });
              params.api.updateRows([
                {
                  ...params.row,
                  resourceOne: { name: newValue ? newValue.label : '' },
                },
              ]);
            }}
            table={'resourceOne'}
            listType={{ id: 'id', name: 'name' }}
            conditions={[`resourceOne.archive = 'N'`]}
          />
        ),
      },
      {
        field: 'timeSpent',
        headerName: 'Idő(óra)',
        flex: 1,
        editable: true,
        renderCell: (params) => {
          return params.row.timeSpent + ' óra';
        },
        renderEditCell: (params) => (
          <Field
            name="timeSpent"
            as={InputUI}
            helperText={<FormErrorUI message={errors.timeSpent} />}
            variant="standard"
            onChange={(e) => {
              setFieldValue('timeSpent', e.target.value ?? '').then(() => {
                validateField('timeSpent');
              });
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              });
            }}
          />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.5,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          const isAnyRowInEditMode = Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit);

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              disabled={isAnyRowInEditMode}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <Formik
            initialValues={currentRow}
            validationSchema={validationSchema}
            onSubmit={processRowUpdate}
            validateOnMount
            enableReinitialize
          >
            {({ errors, setFieldValue, validateField }) => (
              <Form className="w-full">
                <DataGridUI
                  datagridIdentifier={DataGridIdentifiers.AttendanceUsers}
                  sx={{ height: 300 }}
                  columns={columns(setFieldValue, validateField, errors)}
                  rows={rows}
                  getRowId={(row) => row.listId}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      setRows,
                      setRowModesModel,
                      rows,
                      rowModesModel,
                      user,
                      setCurrentRow,
                      id,
                    },
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AttendanceUsers;
