import React, { useMemo, useEffect, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';

import { getDinVal, getIndex } from '../../utils/DynHelper';
import useSetDynField from '../../hooks/useSetDynField';
import { InputUI } from '../Interface';

const DynTextInput = (props) => {
  const { name, disabled, selectedValues, defDyn } = props;
  const { values, setFieldValue } = useFormikContext();
  const setVal = useSetDynField();
  const dynVal = useMemo(
    () => getDinVal(selectedValues.dynamicProperties, name),
    [selectedValues.dynamicProperties, name]
  );

  useEffect(() => {
    if (dynVal) {
      const setterVal =
        dynVal?.textVal !== null && dynVal?.textVal !== ''
          ? dynVal.textVal
          : dynVal?.numberVal !== null && dynVal?.numberVal !== ''
            ? dynVal.numberVal
            : dynVal?.longTextVal !== null && dynVal?.longTextVal !== ''
              ? dynVal.longTextVal
              : defDyn?.[getIndex(name)] !== null
                ? defDyn[getIndex(name)]
                : '';
      setVal(name, setterVal, setFieldValue, values);
    }
  }, [dynVal, name, values, defDyn, setFieldValue, setVal, selectedValues.dynamicProperties]);

  const handleChange = useCallback(
    (e) => {
      setVal(name, e.target.value, setFieldValue, values);
    },
    [name, setFieldValue, values, setVal]
  );

  return (
    <Field
      name={name}
      as={InputUI}
      fullWidth
      variant="standard"
      disabled={disabled}
      onChange={handleChange}
      {...props}
    />
  );
};

export default DynTextInput;
