import { useState } from 'react';
import { Field } from 'formik';

import { TabsUI, FormLabelUI, FormErrorUI, InputUI } from '../Interface';
import DynamicPropertyMain from '../DynamicProperty/DynamicPropertyMain';
import CompanyContact from './CompanyContact';

const DescriptionPanel = (props) => {
  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <Field
            type="text"
            name="companyDescription"
            as={InputUI}
            className={`${props.disabled ? 'rounded-md' : ''} resize-none h-36, p-2`}
            fullWidth
            label={<FormLabelUI text="Leírás" />}
            variant="standard"
            helperText={<FormErrorUI message={props.errors.companyDescription} />}
            disabled={props.disabled}
            InputLabelProps={{ shrink: props.values.companyDescription !== '' }}
            multiline
            rows={4}
          />
        </div>
        <p className="mt-2 leading-6 text-md font-medium text-gray-900">Adja meg a projekthez szükséges leírást.</p>
      </div>
    </div>
  );
};

export default function FormTabs(props) {
  const { submenuId, setFieldValue, validateField, disabled, selectedValues, contact, setRefetchTable, params, user } =
    props;
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Leírás',
      content: <DescriptionPanel {...props} />,
    },
    {
      id: 1,
      name: 'Tulajdonságok',
      content: (
        <DynamicPropertyMain
          submenuId={submenuId}
          setFieldValue={setFieldValue}
          validateField={validateField}
          disabled={disabled}
          selectedValues={selectedValues}
        />
      ),
    },
    {
      id: 2,
      name: 'Kapcsolattartók',
      content: (
        <CompanyContact
          disabled={disabled}
          contact={contact}
          selectedValues={selectedValues}
          user={user}
          params={params}
          setRefetchTable={setRefetchTable}
        />
      ),
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
}
