import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import useUsers from '../../context/UsersContext';
import appService from '../../service/app.service';
import usePages from '../../context/PageContext';
import biService from '../../service/bi.service';

const NavigationItem = ({ item, setSidebarOpen }) => {
  const [visible, setVisible] = useState(false);
  const { setPages } = usePages();

  const handleToggleVisible = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  const handleSubMenuClick = (submenu) => {
    setPages({ menuName: item.menuName, menuId: item.menuId, ...submenu });
    setSidebarOpen(false);
  };

  return (
    <div className="relative my-3 text-white cursor-pointer ml-3">
      <div
        className="flex items-center justify-between hover:bg-gray-800 hover:text-white rounded-md gap-x-3 px-2"
        onClick={handleToggleVisible}
      >
        <p className="font-semibold text-md py-3">{item.menuName}</p>
        <div className="py-3 px-3">
          {visible === false ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
            </svg>
          )}
        </div>
      </div>
      {visible && (
        <ul className="mt-1 space-y-1 px-6">
          {item.submenus.map((subItem) => (
            <Link to={subItem.url === '#' ? '/' : `/app/${subItem.url}`} className="block w-full">
              <li
                onClick={() => handleSubMenuClick(subItem)}
                key={subItem.subMenuName}
                className="text-gray-400 hover:bg-gray-800 hover:text-white group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
              >
                {subItem.subMenuName}
              </li>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
};

const NavigationMenu = ({ setSidebarOpen }) => {
  const [menuData, setMenuData] = useState([]);
  const [filteredMenuData, setFilteredMenuData] = useState([]);
  const [usersubMenuIds, setUsersubMenuIds] = useState([]);
  const [biReports, setBiReports] = useState([]);

  const { user, getUser } = useUsers();

  const fetchUserPermissions = async (roleId) => {
    appService
      .getUserPermissions(roleId)
      .then((data) => {
        setUsersubMenuIds(data);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  const fetchMenuData = async () => {
    appService
      .getMenuData()
      .then((data) => {
        setMenuData(data);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  const getBiReports = () => {
    //@TODO report permissions
    biService.allBiReports().then((data) => {
      setBiReports(data);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserPermissions(user.roleId);
      fetchMenuData();
      getBiReports();
    }
  }, [user]);

  useEffect(() => {
    if (menuData.length > 0 && usersubMenuIds.length > 0) {
      const filteredMenu = menuData.map((item) => {
        let biReportSubmenus = [];
        if (item.url === 'bi') {
          //@TODO report permissions
          biReportSubmenus = biReports.map((report) => ({
            subMenuId: uuidv4(),
            subMenuName: report.name,
            url: `bi/biPage/${report.id}`,
            parentMenuId: item.menuId,
            archive: 'N',
            isFormType: false,
          }));
        }
        const filteredSubmenus = item.submenus.filter((subItem) =>
          usersubMenuIds.some((userSubMenu) => userSubMenu.subMenuId === subItem.subMenuId)
        );
        return {
          ...item,
          submenus: [...filteredSubmenus, ...biReportSubmenus],
        };
      });
      setFilteredMenuData(filteredMenu);
    }
  }, [menuData, usersubMenuIds]);

  return (
    <nav>
      {filteredMenuData.map((item, index) => (
        <NavigationItem key={index} item={item} setSidebarOpen={setSidebarOpen} />
      ))}
    </nav>
  );
};

export default NavigationMenu;
