export const getDinVal = (dinVals, fieldName) => {
  if (!dinVals) return;
  const fieldId = parseInt(fieldName.slice('dynamicFields.din_'.length));
  return dinVals.find((v) => v.propertyId === fieldId);
};

export const getIndex = (fieldName) => {
  return fieldName.slice('dynamicFields.'.length);
};

export const getMultiFieldHeight = (characterLength) => {
  if (characterLength > 1 && characterLength <= 47) {
    return 1;
  } else if (characterLength > 47 && characterLength <= 94) {
    return 2;
  } else if (characterLength > 94 && characterLength <= 141) {
    return 3;
  } else if (characterLength > 141 && characterLength <= 188) {
    return 4;
  } else if (characterLength > 188 && characterLength <= 235) {
    return 5;
  } else if (characterLength > 235 && characterLength <= 282) {
    return 6;
  } else if (characterLength > 282 && characterLength <= 329) {
    return 7;
  } else {
    return 1;
  }
};
