import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';
import { Formik, Form, useFormikContext } from 'formik';

import useUsers from '../../context/UsersContext';
import useMenus from '../../context/MenuContext';
import Yup from '../../utils/yup';
import { PropertyType } from '../../config/property';
import masterDataService from '../../service/masterData.service';
import propertyService from '../../service/property.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

import FormFields from './FormFields';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI } from '../Interface';
import PropertyReports from './PropertyReports';
import FormsDataGrid from './FormsDataGrid';
import SelectListDatagrid from './SelectListDatagrid';

export const PropertyForm = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [propertyOption, setPropertyOption] = useState([]);
  const [showOptionDataGrid, setShowOptionDataGrid] = useState(false);
  const [formProperty, setFormProperty] = useState([]);
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    getUser();
    getMenus();
    getFormTypeSubmenus();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('properties');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    mandatory: Yup.string(),
    type: Yup.string().required(),
    visOrder: Yup.number().nullable().positive().required(),
    characterLength: Yup.number().nullable().positive().max(329),
  });

  const defaultValues = {
    name: '',
    mandatory: false,
    type: '',
    visOrder: '',
    characterLength: '',
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultValues, selectedValues)) {
        valuesToForm();
        setPropertyOption(selectedValues.propertyOption ?? []);
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setInitValues(defaultValues);
      setOperatorsDisabled(true);
    }
  }, [selectedValues]);

  const valuesToForm = () => {
    const { name, mandatory, type, visOrder, formProperty: formValues, characterLength } = selectedValues;

    const updatedFormProperty = formProperty.map((submenu) => ({
      ...submenu,
      checked: formValues?.some((form) => form.formId === submenu.subMenuId) || false,
    }));

    setInitValues({
      name,
      mandatory,
      type,
      visOrder,
      characterLength: characterLength ?? defaultValues.characterLength,
    });
    setFormProperty(updatedFormProperty);
  };

  const getFormTypeSubmenus = () => {
    masterDataService.formTypeSubmenus().then((data) => {
      setFormProperty(data);
    });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
      characterLength: parseInt(values.characterLength),
    };

    propertyService.updateProperty(updData, selectedValues.id).then((_simpleTask) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
      characterLength: parseInt(values.characterLength),
    };

    propertyService
      .createProperty(insertObject)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setInitValues({});
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
      deletedAt: dayjs(new Date()),
    };
    propertyService.deleteProperty(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
    });
  };

  const ShowOptionGrid = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      if (values.type === PropertyType.COMBO) {
        setShowOptionDataGrid(true);
      } else {
        setShowOptionDataGrid(false);
      }
    }, [values.type]);
    return null;
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-4 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100  p-4">
      <div className="col-span-3 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultValues);
              setSelectedValues({});
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <ShowOptionGrid />
                <FormFields
                  viewField={viewField}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                  selectedValues={selectedValues}
                />

                {addField && (
                  <div className="grid grid-cols-5 gap-6 p-2">
                    <div className="col-span-2">
                      <FormsDataGrid
                        formProperty={formProperty}
                        id={selectedValues?.id}
                        disabled={viewField}
                        setRefetchTable={setRefetchTable}
                      />
                    </div>
                    {showOptionDataGrid && (
                      <div className="col-span-3">
                        <SelectListDatagrid
                          property={{ setPropertyOption, propertyOption }}
                          id={selectedValues?.id}
                          setRefetchTable={setRefetchTable}
                          user={user}
                          disabled={viewField}
                          selectedValues={selectedValues}
                          setSelectedValues={setSelectedValues}
                        />
                      </div>
                    )}
                  </div>
                )}

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
      <PropertyReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
